import React from 'react'
import ReactDOM from 'react-dom'

class Loader extends React.Component {

  render(){
    return(
      ReactDOM.createPortal(
        <div className={`_loader-container ${this.props.loading ? '' : '_hide'}`}>
          <div className='_loader-centered-box-container'>
            <span className="_loader" />
          </div>
        </div>,
        document.querySelector("#loader")
      )
    )
  }
}

export default Loader